import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { TopHeader, Section } from "../components/Section"
import Seo from "../components/Seo"

const BlogWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  column-gap: 2.8rem;
  row-gap: 2rem;
  max-width: 80rem;
  margin: 5rem auto 3rem auto;
  padding: 0 1rem;
`

const BigBlog = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
  column-gap: 2.8rem;
  row-gap: 2rem;
  max-width: 80rem;
  margin: 5rem auto 3rem auto;
  padding: 0 1rem;
`

const BlogImage = styled(GatsbyImage)`
  border-radius: 5px;
  -webkit-border-radius: 5p;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
`

const Blog = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.3s;

  .post {
    text-decoration: none;
  }

  .date {
    font-weight: 600;
    padding-top: 0.5rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 0;
    color: black;
  }

  .category {
    display: block;
    color: #00605e;
    font-weight: 700;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  img {
    transition: 0.3s;
  }

  p {
    color: #53575f;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &:hover {
    img {
      filter: grayscale(100%);
      transition: 0.3s;
    }
    transform: scale(1.03);
    transition: 0.3s;
  }
`

const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 45rem;
  list-style: none;
  margin: 1rem auto;
  padding: 1rem;

  .pageNumbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  a {
    margin: 1rem 0.5rem;
  }

  .pageLink {
    padding: 0.5rem 0.9rem;
    border: 2px solid #0d7d83;
    border-radius: 5px;
    font-weight: 600;
  }

  .pageLink:hover {
    background-color: #0d7d83;
    color: white;
  }

  .nextPrev {
    text-decoration: none;
    font-weight: 600;
    padding: 0.5rem 0.9rem;
    border: 2px solid #0d7d83;
    border-radius: 5px;

    &:hover {
      background-color: #0d7d83;
      color: white;
    }
  }
`

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "." : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout>
        <Seo
          title="Blog"
          description="A&D Demolition educates our readers on hazardous waste materials (asbestos) and why it is important to remove it safely. Join us in spreading the words out."
        />
        <Section>
          <TopHeader>
            <h1>Welcome to our blog</h1>
            <h2>
              Here we'll share the latest stories and informative guides on the
              work we do
            </h2>
          </TopHeader>
        </Section>
        <BigBlog>
          {posts.slice(0, 2).map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            //const date = node.frontmatter.date
            const blogURL = node.frontmatter.slug
            const desc = node.excerpt
            const image = node.frontmatter.featuredImage
            const alt = node.frontmatter.imageAlt

            return (
              <Blog key={node.frontmatter.slug}>
                <Link to={blogURL} class="post">
                  <BlogImage
                    image={getImage(image)}
                    alt={alt}
                    objectFit="cover"
                    layout="fullWidth"
                  />
                  <div className="content">
                    {/* <span className="date">{date}</span> */}
                    <h3 className="title">{title}</h3>
                    <p>{desc}</p>
                  </div>
                </Link>
              </Blog>
            )
          })}
        </BigBlog>
        <BlogWrapper>
          {posts.slice(2).map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            //const date = node.frontmatter.date
            const blogURL = node.frontmatter.slug
            const desc = node.excerpt
            const image = node.frontmatter.featuredImage
            const alt = node.frontmatter.imageAlt

            return (
              <Blog key={node.frontmatter.slug}>
                <Link to={blogURL} class="post">
                  <BlogImage
                    image={getImage(image)}
                    alt={alt}
                    objectFit="cover"
                    layout="fullWidth"
                  />
                  <div className="content">
                    {/* <span className="date">{date}</span> */}
                    <h3 className="title">{title}</h3>
                    <p>{desc}</p>
                  </div>
                </Link>
              </Blog>
            )
          })}
        </BlogWrapper>
        <Pagination>
          {!isFirst && (
            <Link to={"/blog/" + prevPage} rel="prev" className="nextPrev">
              Previous Page
            </Link>
          )}
          <div className="pageNumbers">
            {Array.from({ length: numPages }, (_, i) => (
              <Link
                key={`pagination-number${i + 1}`}
                className="pageLink"
                to={`/blog/${i === 0 ? "" : i + 1}`}
                style={{
                  textDecoration: "none",
                  color: i + 1 === currentPage ? "#ffffff" : "",
                  background: i + 1 === currentPage ? "#0D7D83" : "",
                }}
              >
                {i + 1}
              </Link>
            ))}
          </div>
          {!isLast && (
            <Link to={"/blog/" + nextPage} rel="next" className="nextPrev">
              Next Page
            </Link>
          )}
        </Pagination>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      filter: { frontmatter: { contentType: { eq: "blog" } } }
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            imageAlt
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  height: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, PNG]
                )
              }
            }
          }
        }
      }
    }
  }
`
